<template>
  <section class="gardenManagementPage">
    <SearchForm class="dis-flex flex-x-start flex-y-start">
      <el-button type="success" @click="add" class="turnBule">添加商品</el-button>
    </SearchForm>

    <TableContainer title="">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        border
        :header-cell-style="tabHeader"
        :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column prop="id" label="ID" min-width="30" />
        <el-table-column prop="name" label="商品名称" min-width="60" />
        <el-table-column prop="coverPic" label="商品图片" min-width="60">
          <template slot-scope="scope">
            <div>
              <el-popover
              placement="right"
              width="400"
              trigger="click">
               <img  :src="scope.row.coverPic"  width="400px" height="400px" >
            <img  slot="reference" :src="scope.row.coverPic" width="60px" height="60px" >
          </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="originalPrice" label="价格" min-width="60" />
        <el-table-column prop="integral" label="所需积分" min-width="60" />
        <el-table-column prop="userNum" label="每人可兑换数" min-width="60" />
        <el-table-column prop="goodsNum" label="库存" min-width="60" />
        <el-table-column prop="virtualSales" label="虚拟销量" min-width="60" />
        <el-table-column prop="sort" label="排序" min-width="60" />
        <el-table-column width="300" label="操作">
          <template slot-scope="scope">
            <el-button type="text" class="textOrange" @click="editorClick(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" class="textOrange" @click="delteClick(scope.row)"
              >删除</el-button
            >
            <el-button type="text" :class="scope.row.status==1?'col-6':'textOrange' " @click="upDownClick(scope.row,1,0)" :disabled="scope.row.status==1?true:false"
              >{{scope.row.status==1?'已上架':'上架'}}</el-button
            >
            <el-button type="text" :class="scope.row.status==0?'col-6':'textOrange' " @click="upDownClick(scope.row,0,1)" :disabled="scope.row.status==0?true:false"
              >{{scope.row.status==0?'已下架':'下架'}}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <GPagination
        v-show="total > 0"
        ref="ref_GPagination"
        :total="total"
        :page.sync="searchForm.page"
        :size.sync="searchForm.size"
        @pagination="querycshopmallIntegralGoodsPage"
        :page-sizes="pageSizes"
      />
    </TableContainer>

    <!-- 添加活动s -->
    <GDialog
      :dialog.sync="themeDialog"
      @btnSure="submitThemeForm()"
      @btnClose="resetThemeForm()"
      width="80%"
    >
      <el-form ref="ref_themeForm" label-width="100px" @submit.native.prevent>
        <!-- 活动类型 -->
        <div class="dis-flex flex-x-start flex-y-center">
          <el-form-item label="商品库存" label-width="100px">
            <div style="width: 200px" class="dis-flex flex-x-start flex-y-center">
              <el-input
                v-model.trim="addParmas.goodsNum"
                placeholder="请输入"
                type="text"
              />
              <span>件</span>
            </div>
          </el-form-item>
          <el-form-item label="商品货号" label-width="100px">
            <div style="width: 200px">
              <el-input
                v-model.trim="addParmas.attr"
                placeholder="请输入"
                type="text"
              />
            </div>
          </el-form-item>
          <el-form-item label="运费设置" label-width="110px">
            <div style="width: 300px">
              默认模板
            </div>
          </el-form-item>
        </div>
        <div  style='background-color: rgba(74, 224, 230, 0.1);padding-top:24px' class="box-sizing">
          <div class="dis-flex flex-x-start flex-y-center">
          <el-form-item label="商品名称" label-width="100px">
            <div  class="dis-flex flex-x-start flex-y-center">
              <el-input style="width: 300px"
                v-model.trim="addParmas.name"
                placeholder="请输入"
                type="text"
              />
              <div style="width: 80px">
              <el-select
                v-model="addParmas.unit"
                placeholder="单位"
                @change="changeUnit"
                class="t-c"
                style="width: 80px"
              >
                <el-option
                  class="t-c"
                  v-for="(item, index) in unitList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </div>

            </div>
      

          </el-form-item>
         
 
     
        </div>
        
        <div class="dis-flex flex-x-start flex-y-start">
          <el-form-item label="售价" label-width="80px">
            <div style="width:100px" class="dis-flex flex-x-start flex-y-center">
              <el-input
                v-model.trim="addParmas.price"
                placeholder="请输入"
                type="text"
              />
              <span>元</span>
            </div>
           
          </el-form-item>
          <el-form-item label="原价" label-width="80px">
            <div style="width: 100px" class="dis-flex flex-x-start flex-y-center">
              <el-input
                v-model.trim="addParmas.originalPrice"
                placeholder="请输入"
                type="text"
              />
              <span>元</span>
            </div>
          </el-form-item>
          <el-form-item label="重量" label-width="60px">
            <div style="width:100px"  class="dis-flex flex-x-start flex-y-center">
              <el-input
                v-model.trim="addParmas.weight"
                placeholder="请输入"
                type="text"
              />
              <span>g</span>
            </div>
          </el-form-item>
  
          <el-form-item label="所需积分" label-width="90px">
            <div style="width:100px">
              <el-input
                v-model.trim="addParmas.integral"
                placeholder="请输入"
                type="text"
              />
            </div>
          </el-form-item>

          <el-form-item label="商品排序" label-width="100px">
            <div style="width:100px">
              <el-input
                v-model.trim="addParmas.sort"
                placeholder="请输入"
                type="text"
              />
              <span>排序按升序排列</span>
            </div>
          </el-form-item>
          <el-form-item label="虚拟销量" label-width="100px">
            <div style="width:200px">
              <el-input
                v-model.trim="addParmas.virtualSales"
                placeholder="请输入"
                type="text"
              />
              <span>展示销量=实际销量+虚拟销量</span>
            </div>
          </el-form-item>
          <el-form-item label="用户可兑换数量" label-width="120px">
            <div style="width: 100px">
              <el-input
                v-model.trim="addParmas.userNum"
                placeholder="请输入"
                type="text"
              />
            </div>
          </el-form-item>

          
         
        </div>

        <div class="dis-flex flex-x-start flex-y-start">
          <div class="dis-flex flex-x-start flex-y-start">
            <el-form-item label="商品缩略图(324*324)" label-width="180px">
              <el-upload
                action="http://47.100.63.204:8421/manager/common/uploadFileXtg"
                list-type="picture-card"
                :on-preview="wxhandlePictureCardPreview"
                :on-remove="wxhandleRemove"
                :on-success="wxhandleSuccess"
                :file-list.sync="wxfileList"
                :class="addParmas.coverPic !== '' ? 'hide_box' : ''"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="wxdialogVisible">
                <img width="100%" :src="wxdialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
            <el-form-item label="商品图片(750*750)
" label-width="180px">
              <el-upload
                action="http://47.100.63.204:8421/manager/common/uploadFileXtg"
                list-type="picture-card"
                :on-preview="bannerhandlePictureCardPreview"
                :on-remove="bannerhandleRemove"
                :on-success="bannerhandleSuccess"
                :file-list.sync="addParmas.goodsPicList"
              
              >
              <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="bannerdialogVisible">
                <img width="100%" :src="bannerdialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
        
          </div>
        </div>
          
        </div>
     

        <el-form-item label="图文详情" label-width="100px">
          <div>
            <Tinymce :height="600" v-model="tiny.value"></Tinymce>
          </div>
        </el-form-item>
      </el-form>
    </GDialog>
    <!-- 添加活动e -->

  
  </section>
  </section>
</template>

<script>
import { parseTime } from "@utils/filters";
import Tinymce from "./tinymce";
import {
  querycshopmallIntegralGoodsPage,
  addcshopmallIntegralGoods,
  updatecshopmallIntegralGoods,
  delcshopmallIntegralGoods,
} from "@api/bx/api_contentManagement";


export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      themeDialog: {
        title: "添加",
        visible: false,
      },
      tiny: {
        value: undefined,
      },
      searchForm: {
        page: 1,
        size: 10,
      },
      total: 0,
      tableData: [],
      pageSizes: [10, 20, 30, 40, 50],
      unitList: [
        { name: "件", id: "0" },
        { name: "瓶", id: "1" },
      ],
      editor: false,
      bannerfileList: [],
      bannerdialogImageUrl: "",
      bannerdialogVisible: false,
      wxfileList: [],
      wxdialogImageUrl: "",
      wxdialogVisible: false,
      addParmas:{
        goodsNum:'',
        attr:'0',
        freight:0,
        name:'',
        unit:'件',
        price:'0',
        originalPrice:'1',
        weight:'',
        integral:'',
        sort:'',
        status:1,
        useAttr:0,
        isIndex:0,
        virtualSales:'',
        userNum:'',
        coverPic:'',
        goodsPicList:[],
        detail:''
      }

    };
  },
  created() {

    this.querycshopmallIntegralGoodsPage();
  },
  methods: {
    // 文件上传成功后
    bannerhandleSuccess(res) {
      this.addParmas.goodsPicList.push(res.data.rootPath);
    },
    bannerhandleRemove(file, fileList) {
      let arr=[]
      if(this.editor){
        fileList.map((item, index) => {
   
       arr.push(item.url)
 
    });
      }else{
        fileList.map((item, index) => {
   
      arr.push(item.response.data.rootPath)
 
    });
      }
 
      this.addParmas.goodsPicList=arr
    },
    bannerhandlePictureCardPreview(file) {
      this.bannerdialogImageUrl =file.response.data.rootPath;
      this.bannerdialogVisible = true;
    },
    wxhandleSuccess(res) {
      this.addParmas.coverPic = res.data.rootPath;
    },
    wxhandleRemove(file, fileList) {
      this.addParmas.coverPic = "";
    },
    wxhandlePictureCardPreview(file) {
      this.wxdialogImageUrl = file.url;
      this.wxdialogVisible = true;
    },

    tabHeader() {
      return "backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;";
    },

    clearData() {
      this.addParmas.goodsNum=''
      this.addParmas.attr=''
      this.addParmas.freight=0
      this.addParmas.name=''
      this.addParmas.unit='件'
      this.addParmas.price=''
      this.addParmas.originalPrice=''
      this.addParmas.weight=''
      this.addParmas.integral=''
      this.addParmas.sort=''
      this.addParmas.status=1
      this.addParmas.useAttr=0
      this.addParmas.isIndex=0
      this.addParmas.virtualSales=''
      this.addParmas.userNum=''
      this.addParmas.coverPic=''
      this.addParmas.goodsPicList=[]
      this.addParmas.detail=''
      this.tiny.value=undefined
      this.bannerfileList=[]
       this.bannerdialogImageUrl= ""
       this.bannerdialogVisible= false
       this.wxfileList= []
       this.wxdialogImageUrl=""
       this.wxdialogVisible= false
      },


    // 列表
    querycshopmallIntegralGoodsPage() {
      querycshopmallIntegralGoodsPage(this.searchForm).then((res) => {
        this.tableData = res.records;
        this.total = res.total;
      });
    },

    changeUnit(val) {
      this.addParmas.unit = val;
    },

    //添加
    add() {
      this.editor = false;
      this.clearData();
      this.themeDialog.visible = true;
    },
    //确定
    submitThemeForm() {
      this.addParmas.detail = this.tiny.value;
      if(this.editor){
        // console.log('修改')
        let arr=[]
        this.addParmas.goodsPicList.map((item, index) => {
          if(item.status){
            arr.push(item.url)
          }else{
           arr.push(item)
          }
         
      });
      this.addParmas.goodsPicList=arr.join(',')
      }else{
        // console.log('新增')
        if(this.addParmas.goodsPicList.length!==0){
          this.addParmas.goodsPicList=this.addParmas.goodsPicList.join(',')
        }
      }
  
      if (this.addParmas.name == "") {
        this.$alert("请输入商品名称", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.editor) {
        updatecshopmallIntegralGoods(this.addParmas).then((res) => {
          this.themeDialog.visible = false;
          this.clearData();
          this.querycshopmallIntegralGoodsPage();
        });
      } else {
        addcshopmallIntegralGoods(this.addParmas).then((res) => {
          this.themeDialog.visible = false;
          this.querycshopmallIntegralGoodsPage();
        });
      }
    },
    //取消按钮
    resetThemeForm() {
      this.clearData()
      this.themeDialog.visible = false;
      this.querycshopmallIntegralGoodsPage();
    },


    //编辑
    editorClick(rows) {
      this.clearData();
      querycshopmallIntegralGoodsPage({id:rows.id,page: 1,size: 1,}).then((res) => {
        let row = res.records[0];
        this.editor = true;
      this.wxfileList = [
        {
          name: "",
          url: row.coverPic,
        },
      ];
      row.goodsPicList=row.goodsPicList.split(',')
      let arr=[]
      row.goodsPicList.map((item, index) => {
   
        arr.push({
                   name: "",
          url: item,
        })
      
      })
      row.goodsPicList=arr
      this.addParmas = row;
      this.tiny.value = row.detail;
      this.themeDialog.visible = true;
      });
      
 
    },

    //通过审核
    approveClick(row) {
      this.approveRow=row
      this.approveDialog.visible=true
    },
    
    

    //删除
    delteClick(row) {
      this.$confirm("永久删除【" + row.name + "】这条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delcshopmallIntegralGoods({ id: row.id, isDelete: 1 }).then((res) => {
          this.querycshopmallIntegralGoodsPage();
        });
      });
    },
    //上架，下架
    upDownClick(row,status,isDelete){
      console.log(row)
      this.$confirm((status==1?"上架【":"下架【" )+ row.name + "】这件商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        updatecshopmallIntegralGoods({ id: row.id, status: status,isDelete:isDelete }).then((res) => {
          this.querycshopmallIntegralGoodsPage();
        });
      });
    }
  },
};
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.red {
  color: red;
}
.turnBule {
  color: #333;
  background-color: #58e3e8;
  border-color: rgba(56, 185, 190, 0.12);
  margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
  background: rgba(56, 185, 190, 0.5);
  border-color: rgba(56, 185, 190, 0.5);
  color: #fff;
}

.el-button--text.reviewed {
  color: #38b9be;
}

.reviewed {
  color: #38b9be;
}

.el-tree-node__content {
  z-index: 999;
  padding-left: 40px;
}

.directory-in {
  width: 32%;
  line-height: 60px;
  background: #4095e5;
}
.hide_box {
  ::v-deep.el-upload--picture-card {
    display: none;
  }
}
</style>

<style>
.tox-tinymce-aux {
  z-index: 3333333;
}
</style>

